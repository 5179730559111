.link {
  color: var(--colors-link);
  text-decoration: none;

  /* fancy underline */
  background-image: linear-gradient(var(--colors-link-underline), var(--colors-link-underline));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.2s ease-in-out;
  padding-bottom: 3px;
}

.link:hover,
.link:focus-visible {
  background-size: 100% 2px;
}

.link.plain {
  background: none !important;
  transition: none !important;
}
